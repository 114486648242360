define("bottomline-execution/serializers/bx/site", ["exports", "bottomline-execution/serializers/site", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _site, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _site.default.extend({
    store: (0, _service.inject)(),
    siteRemarksSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('site-remark');
    }),
    normalizeQueryResponse: function normalizeQueryResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var mappedSites = payload.map(function (json) {
        return _this.mapResponse(json);
      });
      return {
        data: mappedSites.flatMap(function (s) {
          return s.data;
        }),
        included: mappedSites.flatMap(function (s) {
          return s.included;
        })
      };
    },
    mapResponse: function mapResponse(json) {
      var includedSiteRemarks = this.get('siteRemarksSerializer').mapResponse({
        site_id: json.id,
        remarks: json.remarks
      });
      var mappedSite = {
        id: json.id,
        type: 'site',
        attributes: {
          Address: json.address,
          City: json.city,
          CountryCode: json.country_code,
          latitude: json.latitude,
          longitude: json.longitude,
          Name: json.name,
          SiteType: json.type,
          Telephone: json.phone_number,
          ZipCode: json.zip_code
        },
        relationships: {
          'site_remarks': {
            data: includedSiteRemarks.data.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return {
                type: type,
                id: id
              };
            })
          }
        }
      };
      mappedSite = this._addCustomer(mappedSite, json);
      return {
        data: mappedSite,
        included: includedSiteRemarks.data
      };
    },
    _addCustomer: function _addCustomer(mappedSite, json) {
      if ((0, _utils.isEmpty)(json.customer_type)) return mappedSite;
      var customerId = "site-".concat(json.id, "-customer");
      var existingRecord = this.get('store').peekRecord('customer', customerId);
      if ((0, _utils.isPresent)(existingRecord)) existingRecord.unloadRecord();
      var customer = this.get('store').createRecord('customer', {
        id: customerId,
        type: json.customer_type.toLowerCase()
      });
      mappedSite.attributes.customer = customer;
      return mappedSite;
    }
  });

  _exports.default = _default;
});